
export default {
    data() {
		return {
		}
	},
    watch: {
    },
    created() {
	},
	async mounted() {
	},
	methods: {
	}
}