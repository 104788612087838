<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
                <h5>Rencana Keuangan</h5>
			</div>
		</div>
	</div>
</template>

<script src="./index.js"></script>

<style scoped lang="scss" src="./style.scss"></style>